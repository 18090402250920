import React, {Fragment, useEffect, useState} from 'react';
import {deck} from "./questions";
import {RefreshCcw} from "lucide-react";
import {Dialog, Transition} from '@headlessui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/outline'

declare global {
    interface Navigator {
        getInstalledRelatedApps: () => Promise<any>;
    }
}

let deferredPrompt: any;

window.addEventListener('beforeinstallprompt', (e: Event) => {
    e.preventDefault();
    deferredPrompt = e;
});

function App() {

    const [currentQuestion, setCurrentQuestion] = useState("");

    const [questions, setQuestions] = useState([...deck]);

    const [isFlipped, setIsFlipped] = useState(false);

    const [open, setOpen] = useState(false)

    const drawQuestion = () => {
        if (questions.length === 0) {
            setCurrentQuestion("Hai finito le domande!");
            return;
        }
        setIsFlipped(!isFlipped);

        const randomIndex = Math.floor(Math.random() * questions.length);
        const randomQuestion = questions[randomIndex];
        const newQuestions = [...questions];
        newQuestions.splice(randomIndex, 1);
        setQuestions(newQuestions);
        setTimeout(() => setCurrentQuestion(randomQuestion), 300);
    }

    const resetGame = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setQuestions([...deck]);
        setCurrentQuestion("");
    }

    const [isAppInstalled, setIsAppInstalled] = useState(false);

    const installApp = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                deferredPrompt = null;
            });
        }
    };

    useEffect(() => {

        // Check localstorage first
        const isAppPreviouslyInstalled = localStorage.getItem('isAppInstalled') === 'true';
        if (isAppPreviouslyInstalled) {
            setIsAppInstalled(true);
            return;
        }

        window.addEventListener('appinstalled', () => {
            // When app is installed, save this info in localstorage
            localStorage.setItem('isAppInstalled', 'true');
            setIsAppInstalled(true);
        });

        window.addEventListener('beforeinstallprompt', function (evt) {
            evt.preventDefault();

            // Stash the event so it can be triggered later.
            deferredPrompt = evt;
        });
    }, []);

    return (
        <div className="flex flex-col h-screen bg-gray-200">
            <div
                className={"mx-auto w-full px-4 max-w-7xl flex-grow py-10 flex flex-col items-center justify-between"}>
                <div className={"flex w-full justify-between items-center"}>
                    <div className={"text-3xl font-bold"}>
                        Effetto Mandria
                    </div>
                    <QuestionMarkCircleIcon className={"h-6 w-6 cursor-pointer"} onClick={() => setOpen(true)}/>
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={setOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel
                                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                            <div>
                                                <div className="mt-3 sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-base font-semibold leading-6 text-gray-900">
                                                        Come Giocare
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            Il Mandriano inizia leggendo una carta ad alta voce.
                                                            Ogni giocatore (incluso il Mandriano) scrive una
                                                            risposta in segreto sul suo foglio delle risposte.
                                                            Quando tutti sono pronti, rivelate le risposte uno
                                                            alla volta, partendo dal Mandriano.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-3 sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-base font-semibold leading-6 text-gray-900">
                                                        Fare Punti
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            Quando tutte le risposte sono state rivelate,
                                                            confrontatele e contatele per scoprire qual è la più
                                                            gettonata.
                                                            Se la vostra risposta fa parte della maggioranza,
                                                            ottenete 1 mucca come ricompensa.
                                                            Congratulazioni, la vostra mandria è appena
                                                            diventata un pochino più grande.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-3 sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-base font-semibold leading-6 text-gray-900">
                                                        La Mucca Rosa
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            Se invece siete l’unico giocatore ad aver scritto una
                                                            risposta che non corrisponde a quella di nessun’altro,
                                                            allora siete l’eccezione. Per punizione, dovete
                                                            aggiungere la Mucca Rosa alla vostra mandria.
                                                            L’unico modo che avete per liberarvi della Mucca
                                                            Rosa è attendere che un altro giocatore diventi
                                                            l’eccezione in un round successivo. Mentre siete in
                                                            possesso della Mucca Rosa, potete continuare a
                                                            ottenere mucche, ma non potete vincere la partita.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    Ho capito
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>


                <div className={"group cursor-pointer w-full"}>
                    <div className={
                        "relative w-full text-white transition-all duration-1000 h-64 [transform-style:preserve-3d]" +
                        (isFlipped ? " [transform:rotateY(180deg)]" : "")
                    }>
                        <div
                            className={"flex flex-col bg-gradient-to-tl from-pink-500 to-yellow-500 h-full items-center justify-center p-10 rounded-3xl"}
                            onClick={drawQuestion}
                        >
                            <div className={"text-center font-semibold text-2xl"}>
                                {currentQuestion ? currentQuestion : "Inizia il gioco"}
                            </div>
                        </div>
                        <div
                            className={"absolute inset-0 bg-gradient-to-tl from-yellow-500 to-pink-500 h-full flex flex-col items-center justify-between p-10 rounded-3xl [transform:rotateY(180deg)] [backface-visibility:hidden]"}
                            onClick={drawQuestion}
                        >
                            <div
                                className={"text-center h-full items-center flex justify-center font-semibold text-2xl"}>
                                {currentQuestion ? currentQuestion : "Click to draw a question"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-center gap-4"}>
                    <div className={"flex gap-20"}>
                        <div>
                            Domande Rimaste: {questions.length}
                        </div>
                        <button
                            type={"button"}
                            onClick={resetGame}
                        >
                            <RefreshCcw
                                className={"h-4 w-4"}
                            />
                        </button>
                    </div>
                    {
                        !isAppInstalled && (
                            <div
                                className={"bg-gray-400 text-white px-4 py-2 rounded-xl cursor-pointer"}
                                onClick={installApp}
                            >
                                Install
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
