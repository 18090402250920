export const deck = [
    "Cosa portereste con voi su un'isola deserta?",
    "Qual è la parte migliore di un pasto: l'antipasto, il primo, il secondo o il dolce?",
    "Nominate un tipo di cappello",
    "Qual è il dinosauro più simpatico?",
    "Preferireste parlare come Yoda o come Darth Vader?",
    "Se poteste truccare il cappello parlante di Harry Potter, quale casa vi piacerebbe che vi assegnasse?",
    "In quale regione italiana vorreste andare in vacanza?",
    "Qual è il miglior dito della mano?",
    "Indossate una maglietta bianca nuova. Quale cibo dovreste assolutamente evitare?",
    "Di quale grande civiltà del passate avreste voluto far parte?",
    "Qual è il miglior modo per mangiare un uovo?",
    "Qual è il miglior tipo di patatine in busta?",
    "Nominate qualcosa di viola",
    "Con quale supereroe andreste a cena?",
    "Nominate uno stato in cui si parla spagnolo che non sia la Spagna",
    "Qual è la migliore moneta inferiore a 1 euro?",
    "Ogni giorno: Natale, Halloween o il vostro compleanno?",
    "A quale età pensate che la maggior parte delle persone dia il primo bacio?",
    "Qual è il miglior cocktail?",
    "Nominate una verdura che inizia con la C.",
    "Quale è la materia scolastica più inutile?",
    "Un orso vi corre incontro. Cosa fate?",
    "Nominate un animale della fattoria che non mangereste mai",
    "Nominate un lavoro che inizia con la B",
    "Quale è la cosa migliore proveniente dal Regno Unito? La Regina, i Beatles o James Bond?",
    "Quel è la zona esteticamente peggiore per un tatuaggio?",
    "Qual è il giorno peggiore della settimana lavorativa?",
    "Qual è il miglior dessert?",
    "Qual è la migliore canzone dei Queen?",
    "Qual è la migliore filastrocca?",
    "Meglio avere una scorta infinita di cioccolato, di gelato o di patatine?",
    "Nominate il titolo di un film che contenga un colore",
    "Prima i cereali o prima il latte?",
    "Di quante paia di scarpe necessita mediamente una persona?",
    "Nominate una verdura che i bambini odiano",
    "Preferireste che la vostra pelle fosse ricoperta di squame o di piume?",
    "Quale è il miglior film di Natale?",
    "Qual è l'app più utile da avere sul telefono?",
    "Preferireste essere un coccodrillo, una iena o un rinoceronte?",
    "Nominate qualcosa che pesa più di una tonnellata",
    "Qual è l'arma bianca migliore per un duello?",
    "Nominate qualcosa che spaventerebbe un cane",
    "Qual è il genere di programma televisivo preferito dai nonni?",
    "Nominate qualcosa che si usa al mare",
    "Se aveste una macchina del tempo, fareste un salto nel passato o nel futuro?",
    "Nominate un brand che inizia con la A",
    "Miglior corso di scienze: fisica, chimica o biologia?",
    "Qual è la cosa più grande a cui riuscite a pensare?",
    "Nominate uno stato che inizia con la G",
    "Qual è la stanza migliore della casa?",
    "Nominate uno stato molto caldo",
    "Qual è l'animale più veloce privo di zampe?",
    "Se poteste vincere uno quale scegliereste: Oscar, Oro Olimpico o Nobel?",
    "Qual è la migliore salsa?",
    "Nominate qualcosa che perdete man mano che invecchiate",
    "Tra i presenti in questa stanza, chi è quello vestito meglio oggi?",
    "Tirate un dado, che numero è uscito?",
    "Nominate un cibo giallo",
    "Se poteste essere un animale della fattoria, quale scegliereste?",
    "Qual è la cosa migliore proveniente dagli USA, gli hamburger, l'iPhone o il basket?",
    "Nominate una bevanda che inizia con la C",
    "Nominate un mago famoso",
    "Se poteste parlare la lingua dei gatti, cosa significherebbe 'Miao'?",
    "Qual è il sintomo influenzale più fastidioso?",
    "Se foste un albero, quale vorreste essere?",
    "Se poteste avere un occhio in più, dove lo mettereste?",
    "Qual è la cosa più scivolosa del mondo?",
    "Qual è il numero ideale di pagine per un libro?",
    "Preferireste mangiare cibo troppo salato o troppo dolce?",
    "Qual è il miglior superpotere?",
    "Qual è la parte migliore del viso?",
    "Qual è la miglior giostra di un luna park?",
    "Qual è l'animale più piccolo che potrebbe sopportare il peso di un uomo? (non di una femminista)",
    "Qual è il miglior gusto di gelato?",
    "Nominate una catastrofe apocalittica",
    "Qual è il miglior condimento per una pizza?",
    "Quale presidente degli USA vi piacerebbe incontrare?",
    "Nominate qualcosa che si può trovare su una spiaggia",
    "Nominate qualcosa che trovate nella tasca posteriore dei pantaloni",
    "Quanti giorni pensate di poter passare senza lavarvi prima che le persone inizino a notarlo?",
    "Nominate un robot famoso",
    "A che ora la colazione diventa pranzo?",
    "Nominate un pianeta del sistema solare che non sia la Terra",
    "Nominate qualcosa che si trova sott'olio",
    "Nominate un John famoso",
    "Nominate una parte del corpo di cui una persona ne ha una sola",
    "Dopo quanti giorni la pizza avanza diventa immangiabile?",
    "Qual è il miglior salume per farci un panino?",
    "Qual è il miglior film dell'universo di Star Wars?",
    "Qual è il pasto migliore della giornata?",
    "Nominate un topo di fantasia",
    "Qual è la torta più buona?",
    "Nominate una catena di supermercati",
    "Qual è la carne più gustosa?",
    "Nominate una trilogia di film",
    "Nominate qualcosa che portereste cin voi nella vasca da bagno",
    "Se doveste scegliere un altro nom per la persona che legge questa domanda quale sarebbe?",
    "Qual è il miglior colore di biancheria intima?",
    "Qual è la disciplina più inutile delle olimpiadi?",
    "Chi fareste risorgere: Freddie Mercury, Michael Jackson, David Bowie o Kurt Cobain?",
    "Nominate uno dei sette nani",
    "Nominate un orso di fantasia",
    "Quale è la parte peggiore di un insalatone?",
    "Quale è il calciatore più forte di sempre?",
    "Quale è il tatuaggio più banale?",
    "Nominate una fobia",
    "Preferireste avere troppo caldo o troppo freddo?",
    "Nominate qualcosa con le bollicine",
    "Qual è il vostro animale australiano preferito?",
    "Qual è l'animale con la corna più maestose?",
    "Qual è il miglior biscotto in commercio?",
    "Nominate un fiore con la lettera G",
    "Nominate un cibo che mangiate il giorno di Natale",
    "Da quante ore è composta la notte di sonno perfetta?",
    "È nato prima l'uovo o la gallina?",
    "Avete un pacchetto di M&M's, ne prendete uno a caso. Quale colore è uscito?",
    "Quale animale che vice in acqua vi piacerebbe essere?",
    "Qual è lo strumento più facile da suonare?",
    "Nominate il film di Tom Hanks più sottovalutato",
    "Qual è l'oggetto più grande dotato di ruote che potreste sollevare?",
    "Qual è il mestiere che non vorresti mai fare?",
    "Quel è l'elettrodomestico più utile?",
    "Nominate una parte del vostro corpo con la quale potreste vivere comunque",
    "Qual è la cosa più preziosa del mondo?",
    "Qual è la verdura o il frutto più pesante?",
    "Qual è il mese di cui si può fare a meno?",
    "Qual è il genere musicale più noioso?",
    "Nominate un tipo di frutta secca",
    "Qual è il mezzo di trasporto pubblico più comodo?",
    "Nominate un uccello che non sia in grado di volare",
    "Nominate un film horror famoso",
    "Nominate un cibo solido che non dovete masticare",
    "Nominate un oggetto nell'astuccio",
    "Nominate una parte del corpo della mucca",
    "Qual è l'ultima cosa che fare prima di andare a dormire?",
    "Qual è la migliore bevanda a base di latte?",
    "Qual è la capotale europea più divertente?",
    "Nominate qualcosa che potete cucinare in forno",
    "Qual è il migliore: Netflix, Amazon Prime o Disney+?",
    "I regali di Natale si aprono prima od dopo il pranzo del 25?",
    "Nominate un frutto che sarebbe difficile da usare facendo il giocoliere",
    "Quale sarebbe il miglior gioco televisivo a premi a cui partecipare?",
    "Qual è il 'crimine' peggiore: gettare rifiuti per terra, sputare o saltare la fila?",
    "Nominate una creatura mitologica/lengendaria",
    "Nominate un drago famoso",
    "Quale decennio del XX secolo aveva la moda migliore?",
    "Qual è lo sport più noioso da guardare?",
    "Qual è il miglior modo di fare le patate: purè, fritte o al forno?",
    "Nominate uno stato molto freddo",
    "Qual è il numero ideale di figli?",
    "Scrivete il verso di un animale",
    "Preferireste avere una statua o un dipinto di voi stessi?",
    "Preferireste possedere una villa ai tropici, uno yacht o un appartamento a Manhattan?",
    "Nominate un rapper",
    "Nominate una creatura marina che non mangereste mai",
    "Nominate una città italiana che inizia con la B",
    "Nominate uno dei sette peccati capitali",
    "Quale animale estinto riportereste i vita: stegosauro, mammut o la tigre dai denti a sciabola?",
    "Ti presenti in questa stanza, chi potrebbe essere l'assassino?",
    "Qual è l'abitudine peggiore: scaccolarsi, mangiare rumorosamente o mangiarsi le unghie?",
    "Qual è la prima cosa che fate al mattino?",
    "Nominate il film di Steven Spielberg più triste",
    "Qual è la peggior marca di automobili?",
    "Qual è il mestiere più redditizio?",
    "Nominate qualcosa che si presenta in coppia",
    "Nominate una parola che inizia con la lettera Z",
    "Qual è il piatto cinse più gettonato?",
    "Qual è il miglior personaggio dei Friends?",
    "Quale animale selvatico sarebbe l'animale domestico più figo?",
    "Preferireste essere in grado di capire gli animali o farvi capire da loro?",
    "Qual è la principessa Disney più tosta?",
    "Quanti Big Mac potreste mangiare in 20 minuti?",
    "Qual è il cibo più appiccicoso?",
    "Qual è la cosa migliore proveniente dalla Francia? Chanel, Champagne, le crepes o nulla?",
    "Tra tutti gli animale del mondo, qual è il più carino?",
    "Nominate un tipo di cosmetico",
    "Nominate una boy band famosa",
    "Quante ore dovrebbe durare una giornata?",
    "Qual è il nome migliore per una mucca?",
    "Qual è la cosa più piccola a cui riuscite a pensare?",
    "Se poteste fare parte di una famiglia di un cartone animato, quale scegliereste?",
    "Tra i presenti in questa stanza, chi ha maggiori probabilità di andare in paradiso?",
    "Se poteste imparare a suonare uno strumento quale scegliereste?",
    "Nominate un animale che inizia con la lettera P",
    "Qual è il frutto più buono?",
    "Qual è la stagione migliore?",
    "Qual è il miglior formato di pasta?",
    "Tra tutti gli animali del mondo, qual è il più arrabbiato?",
    "Preferireste un jet pack, un maggiordomo robot, o un auto che si guida da sola?",
    "Qual è il miglior presentatore televisivo italiano?",
    "Nominate un legume",
    "Nominate una persona famosa con i capelli rossi",
    "Meglio essere la persona più ricca del mondo o la più intelligente?",
    "Qual è il miglior film Disney di tutti i tempi?",
    "Tra i presenti in questa stanza, chi verrebbe ucciso per primo in un film horror?",
    "Nominate una specie di serpente",
    "Nominate una band che inizia con la R",
    "Qual è il miglior frutto di bosco?",
    "Tra i presenti in questa stanza, chi è più a rischio di beccarsi una tumore?",
    "Tra tutti gli amici di topolino chi è il più fascista?",
];
